import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
export const WoodmixContext = createContext();
export const WoodmixProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
   // console.log('colled');
    setIsLoading(true);
    axios
      .get('https://woodmix.pk/woodmix/read.php')
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
       // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <WoodmixContext.Provider value={{ data, isLoading }}>
      {children}
    </WoodmixContext.Provider>
  );
};
