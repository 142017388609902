import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { WoodmixContext } from '../context';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: {max: 767, min: 0  },
    items: 1,
    slidesToSlide: 1,
  },
};

const Slider = () => {
  const { data } = React.useContext(WoodmixContext);
  const { best_product } = data;
  return (
    <section id="PopularDesign">
      <div className='container bg-white rounded'>
        <div className='section-title pt-4 pb-0 border-bottom mb-1'>
          <h2>Popular Products</h2>
          <p>
          
"Our popular products combine the timeless beauty of wood with the durability and eco-friendliness of WPC materials. Elevate your living space with furniture that's built to last, making a statement in both elegance and environmental responsibility. Explore Woodmix WPC furniture today and embrace a harmonious blend of nature and design."
          </p>
        </div>
        <div className='parent'>
          <Carousel
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={true}
            partialVisible={false}
            dotListClass='custom-dot-list-style'
          >
            {best_product.map(
              ({ product_img, product_name, id }) => {
                return (
                  <div className='col-sm-4   slider' style={ {display: 'grid',
                    placeItems: 'center',margin:'0px'}} key={id} >
                    <div
                      className='card'
                      style={{
                        borderRadius: '10px 10px 10px 10px',
                        height: '300px',
                        width: '200px',
                        backgroundColor: '#5cb874',
                        color:'#fff'
                
                      }}
                    >
                      <div className='image'>
                        <img
                          className=''
                          src={`https://woodmix.pk/woodmix/image/${product_img}`}
                          style={{
                            borderRadius: '10px 10px 10px 10px',
                            height: '200px',

                          }}
                          alt='product images'
                        ></img>
                      </div>
                      <div className='card-inner pt-4' style={{marginTop:'auto',marginBottom:'auto'}}>
                        <div className='header text-center  align-bottom '>
                          <h3>{product_name}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </Carousel>
        </div>
      </div>
    </section>
  );
};
export default Slider;
