import React from 'react';
import { WoodmixContext } from '../../context';

function Hero() {
  const { data } = React.useContext(WoodmixContext);
  const { hero } = data;

    const handleSubmitupdatehero = (event) => {
      event.preventDefault();
  
      // Make the form submission request
      const formData = new FormData(event.target);
      
      fetch('https://woodmix.pk/woodmix/updateHero.php', {

        method: 'POST',
        encType:'multipart/form-data',
        body: formData,
        mode: 'no-cors',
      })
        .then(() => {
          // Refresh the page
        // window.location.reload();
        })
        .catch((error) => {
          console.error('Request failedwwww:', error);
        });
    };

    const handleSubmitaddnewhero = (event) => {
      event.preventDefault();
  
      // Make the form submission request
      const formData = new FormData(event.target);
      
      fetch('https://woodmix.pk/woodmix/hero.php', {

        method: 'POST',
        encType:'multipart/form-data',
        body: formData,
      })
        .then(() => {
          // Refresh the page
        //  window.location.reload();
        })
        .catch((error) => {
          console.error('Request failed:', error);
        });
    };

  return (
    <section>
      {hero?.map(({ hero_id, hero_heading, hero_disc, /* hero_img */ }) => {
        return (
          <>
            <form onSubmit={handleSubmitupdatehero} key={hero_id} className='container d-flex gap-1 flex-wrap'>
              <div className='d-flex flex-column'>
                <input defaultValue={hero_id} type='hidden' name='hero_id' />
              </div>
              <div className='container'>
                <div className='row'> 
                 <div className='col-12'>
                    <div className='d-flex flex-column'>
                      <label htmlFor='heading'>Heading</label>
                      <textarea defaultValue={hero_heading} type='text' name='heading' style={{ width: 'auto' }} />
                    </div>
                 </div>
                 <div className='col-12'>
                    <div className='d-flex flex-column'>
                      <label htmlFor='discription'>Description</label>
                      <textarea defaultValue={hero_disc} type='text' name='description' style={{ width: 'auto' }} />
                    </div>
                 </div>
                 <div className='col-12'>
                  <input type='file' name='img' />
                 </div>
                 <div className='col-12'>
                  <input type='submit' className='btn btn-sm h-4' value='update' />
                 </div>
                </div>
              </div>
            </form>
            <div className='border-bottom-0 border mt-5'></div>
          </>
        );
      })}
      <h2>Add New</h2>
      <form   onSubmit={handleSubmitaddnewhero}    >
        <div className='d-flex flex-column'>
          <label for='heading'>Heading</label>
          <textarea type='text' name='hero_heading' style={{ width: 'auto' }} />
        </div>
        <div className='d-flex flex-column'>
          <label for='discription'>Discription</label>
          <textarea type='text' name='hero_disc' style={{ width: 'auto' }} />
        </div>
        <div className='mt-4'>
          <input type='file'id="myFile" name="img" />
          <input type='submit' className='btn btn-sm' defaultValue='update' />
        </div>
      </form>
    </section>
  );
}


function About() {
  const { data } = React.useContext(WoodmixContext);
  const { about } = data;
  const { about_heading, about_disc, about_p, /* about_img  */} = about;

  const handleSubmitupdateabout = (event) => {
    event.preventDefault();

    // Make the form submission request
    const formData = new FormData(event.target);
    
    fetch('https://woodmix.pk/woodmix/updateAbout.php', {

      method: 'POST',
      encType:'multipart/form-data',
      body: formData,
    })
      .then(() => {
        // Refresh the page
      //  window.location.reload();
      })
      .catch((error) => {
        console.error('Request failed:', error);
      });
  };


  return (
    <section>
      <form
        onSubmit={handleSubmitupdateabout}
        className='container d-flex gap-1  flex-wrap'
      >
        <div className='mb-3'>
          <label for='heading' className='form-label'>
            Heading
          </label>
          <textarea
            defaultValue={about_heading}
            type='text'
            id='heading'
            name='heading'
            className='form-control d-block'
            style={{ width: 'auto' }}
          />
        </div>
        <div className='mb-3'>
          <label for='paragraph' className='form-label'>
            Paragraph
          </label>
          <textarea
            defaultValue={about_p}
            type='text'
            name='paragraph'
            id='paragraph'
            className='form-control d-block'
            style={{ width: 'auto' }}
          />
        </div>
        <div className='mb-3'>
          <label for='discription' className='form-label'>
            Discription
          </label>
          <textarea
            defaultValue={about_disc}
            type='text'
            name='discription'
            className='form-control d-block'
            id='discription'
            style={{ width: 'auto' }}
          />
        </div>
        <input type='file' name='img' id='img' />
        <input type='submit' name='submit' value='update' className='btn btn-sm h-4' />
      </form>
      <div className='border-bottom-0 border'></div>
    </section>
  );
}



function BestProduct() {
  const { data } = React.useContext(WoodmixContext);
  const { best_product } = data;

  const handleSubmitBestProductupdate = (event) => {
    event.preventDefault();

    // Make the form submission request
    const formData = new FormData(event.target);
    
    fetch('https://woodmix.pk/woodmix/updateProduct.php', {

      method: 'POST',
      encType:'multipart/form-data',
      body: formData,
    })
      .then(() => {
        // Refresh the page
      //  window.location.reload();
      })
      .catch((error) => {
        console.error('Request failed:', error);
      });
  };

  const handleSubmitBestProductaddnew = (event) => {
    event.preventDefault();

    // Make the form submission request
    const formData = new FormData(event.target);
    
    fetch('https://woodmix.pk/woodmix/best.php', {

      method: 'POST',
      encType:'multipart/form-data',
      body: formData,
    })
      .then(() => {
        // Refresh the page
      //  window.location.reload();
      })
      .catch((error) => {
        console.error('Request failed:', error);
      });
  };


  return (
    <section>
      {best_product?.map(({ product_ID, product_name,/* product_img  */}) => {
        return (
          <>
            <form
              onSubmit={handleSubmitBestProductupdate}
              key={product_ID}
              className='container d-flex gap-1  flex-wrap'
            >
              <div className='mb-3'>
          
                <input defaultValue={product_ID} type='hidden'name='id'/>
              </div>
              <div className='mb-3'>
                <label for='name' className='form-label'>
                  Name
                </label>
                <textarea
                  defaultValue={product_name}
                  type='text'
                  name='name'
                  className='form-control d-block'
                  style={{ width: 'auto' }}
                />
              </div>
           
              <input type='file' name='img' />
              <input type='submit' className='btn' defaultValue='update' />
            </form>
            <div className='border-bottom-0 border'></div>
          </>
        );
      })}
      <h2>Add New</h2>
      <form
        onSubmit={handleSubmitBestProductaddnew}
      >
        <div className='d-flex flex-column'>
          <label for='product_name'>Product Name</label>
          <textarea type='text' name='product_name' style={{ width: 'auto' }} />
        </div>
 
        <div className='mt-4'>
          <input type='file' name='img' />
          <input type='submit' className='btn btn-sm' defaultValue='update' />
        </div>
      </form>
    </section>
  );
}
function Portfolio() {
  const { data } = React.useContext(WoodmixContext);
  const { portfolio_product } = data;

  const handleSubmitportfolioupdate = (event) => {
    event.preventDefault();

    // Make the form submission request
    const formData = new FormData(event.target);
    
    fetch('https://woodmix.pk/woodmix/updatePortfolio.php', {

      method: 'POST',
      encType:'multipart/form-data',
      body: formData,
    })
      .then(() => {
        // Refresh the page
      //  window.location.reload();
      })
      .catch((error) => {
        console.error('Request failed:', error);
      });
  };

  const handleSubmitportfolioaddnew = (event) => {
    event.preventDefault();

    // Make the form submission request
    const formData = new FormData(event.target);
    
    fetch('https://woodmix.pk/woodmix/portfolio.php', {

      method: 'POST',
      encType:'multipart/form-data',
      body: formData,
    })
      .then(() => {
        // Refresh the page
      //  window.location.reload();
      })
      .catch((error) => {
        console.error('Request failed:', error);
      });
  };




  return (
    <section>
      {portfolio_product?.map(
        ({ portfolio_ID, portfolio_heading, portfolio_category, /* portfolio_img  */}) => {
          return (
            <>
              <form
                onSubmit={handleSubmitportfolioupdate}
                key={portfolio_ID}
                className='container d-flex gap-1  flex-wrap'
              >
                <div className='mb-3'>
                
                  <input
                    defaultValue={portfolio_ID}
                    type='hidden'
                    name='id'
                    
                  />
                </div>
                <div className='mb-3'>
                  <label for='heading' className='form-label'>
                    Heading
                  </label>
                  <textarea
                    defaultValue={portfolio_heading}
                    type='text'
                    name='heading'
                    className='form-control d-block'
                    style={{ width: 'auto' }}
                  />
                </div>
                <div className='mb-3'>
                  <label for='category' className='form-label'>
                    Category
                  </label>
                  <input
                    defaultValue={portfolio_category}
                    type='text'
                    className='form-control d-block'
                    name='category'
                    style={{ width: '100px' }}
                  />
                </div>
                <div class='mb-3'>
                  <input type='file' name='img' />
                  <input type='submit' className='btn' defaultValue='update' />
                </div>
              </form>
              <div className='border-bottom-0 border'></div>
            </>
          );
        }
      )}
      <h2>Add New</h2>
      <form
       onSubmit={handleSubmitportfolioaddnew}
      >
        <div className='d-flex flex-column'>
          <label for='heading'>Product Name</label>
          <textarea
            type='text'
            name='portfolio_heading'
            style={{ width: 'auto' }}
          />
        </div>
        <div className='d-flex flex-column'>
          <label for='category'>Product category</label>
          <textarea
            type='text'
            name='portfolio_category'
            style={{ width: 'auto' }}
          />
        </div>
        <div className='mt-4'>
          <input type='file' name='img' />
          <input type='submit' className='btn btn-sm' defaultValue='update' />
        </div>
      </form>
    </section>
  );
}

function Contact() {
  const { data} = React.useContext(WoodmixContext);
  const { contact } = data;
  const { contact_number, contact_email, contact_location } = contact;


  const handleSubmitUpdateContact = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const requestData = Object.fromEntries(formData.entries());

    fetch('https://woodmix.pk/woodmix/updateContact.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {

        window.alert("Contact section updated..");

      })
      .catch(error => console.error(error))

  };
  return (
    <section style={{ border: '2px solid ', backgroundColor: 'darkseagreen', borderRadius: '22px', color: 'white' }}>

      <form onSubmit={handleSubmitUpdateContact}>
        <div className='container'>
          <div class="mb-3">
            <label for="number" className="form-label">Contact Number</label>
            <input defaultValue={contact_number} name='number' type="text" className="form-control" />
          </div>
          <div class="mb-3">
            <label for="email" className="form-label">Email</label>
            <input defaultValue={contact_email} name='email' type="email" className="form-control" />
          </div>
          <div class="mb-3">
            <label for="location" class="form-label"> Location(Address)</label>
            <textarea defaultValue={contact_location} name='location' className="form-control" rows="5"></textarea>
          </div>

          <div className='float-right'>
            <input type='submit' className='btn' defaultValue='update' />
          </div>

        </div>
      </form>
    </section>
  );
}
export { Hero, About, BestProduct, Portfolio, Contact };