import React from 'react';

const Menu = ({ items }) => {
  return (
    <div className='row portfolio-container'>
      {items.map(
        ({ id, portfolio_heading, portfolio_category, portfolio_img }) => {
          return (
            <div key={id} className='col-lg-4 col-md-6 m-auto portfolio-item'>
              <div className='portfolio-wrap '>
                <img
                  src={`https://woodmix.pk/woodmix/image/${portfolio_img}`}
                  className='img-fluid'
                  alt=''
                />
                <div className='portfolio-info'>
                  <h4>{portfolio_heading}</h4>
                  <p>{portfolio_category}</p>
                  <div className='portfolio-links'>
                    <a
                      href={`https://woodmix.pk/woodmix/image/${portfolio_img}`}
                      data-gallery='portfolioGallery'
                      className='portfolio-lightbox'
                      title={portfolio_heading}
                    >
                      <i className='bx bx-plus'></i>
                    </a>
                    <a href='portfolio-details.html' title='More Details'>
                      <i className='bx bx-link'></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};
export default Menu;
