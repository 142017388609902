import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Hero, About, BestProduct, Portfolio, Contact } from './form';

export default function LabTabs() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='container'>
      <Box sx={{ width: '100%', typography: 'body1', overflowX: 'hidden' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider',border:'0.5px solid e0e0e0' ,backgroundColor: 'darkseagreen' }}>
            <TabList
              onChange={handleChange}
              aria-label='lab API tabs example'
              variant='scrollable'
              scrollButtons='auto'
              sx={{
                display: 'flex',
                '& .MuiTab-root': {
                  backgroundColor: 'darkseagreen',
                  color: '#000',
                  borderRadius: '8px',
                  margin: '4px',
                  padding: '8px 16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                },
                '& .Mui-selected': {
                  backgroundColor: 'darkseagreen',
                  color: 'white',
                  borderRadius: '8px',
                  margin: '4px',
                  padding: '8px 16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                },
              }}
            >
              <Tab label='Hero Section' value='1' />
              <Tab label='About Section' value='2' />
              <Tab label='Best Product Section' value='3' />
              <Tab label='Portfolio Section' value='4' />
              <Tab label='Contact Section' value='5' />
            </TabList>
          </Box>
          <TabPanel value='1'>
            <Hero />
          </TabPanel>
          <TabPanel value='2'>
            <About />
          </TabPanel>
          <TabPanel value='3'>
            <BestProduct />
          </TabPanel>
          <TabPanel value='4'>
            <Portfolio />
          </TabPanel>
          <TabPanel value='5'>
            <Contact />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
