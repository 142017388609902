import Home from './pages/home';
import Error from './pages/Error';
import Admin from './pages/admin';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/admin" element={<Admin />} />
      <Route path="*" element={<Error />} />
    </Routes>
  </BrowserRouter>
);
}


export default App;
