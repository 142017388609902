import React from 'react';

const Categories = ({ categories, filterItems }) => {
  return (
    <div className='btn-container'>
      {categories.map((portfolio_category, index) => {
        return (
          <li  key={index} onClick={() => filterItems(portfolio_category)}>
            {portfolio_category}
          </li>
        );
      })}
    </div>
  );
};

export default Categories;
