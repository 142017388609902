import React from 'react';
// import TopBar from '../components/top-bar';
import Header from '../components/header';
import HeroSection from '../components/hero';
import About from '../components/about';
import PopDesigns from '../components/popularDesigns';
import Portfolio from '../components/portfolio/portfolio';
import Contact from '../components/contact';
import Footer from '../components/footer';
import { WoodmixContext } from '../context';
import loadingImage from '../preloader.gif';
function Home() {
  const { isLoading } = React.useContext(WoodmixContext);
  if (isLoading) {
    return (
      <main>
        {/* <TopBar isLoading = {true}/> */}
        <Header />
        <img src={loadingImage} className='loading-img' alt='loading' />;
      </main>
    );
  }
  return (
    <main>
      {/* <TopBar /> */}
      <Header />
      <HeroSection />
      <About />
      <PopDesigns />
      <Portfolio />
      <Contact />
      <Footer />
    </main>
  );
}
export default Home;
