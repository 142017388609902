import React, { useState } from 'react';
const Header = () => {
  const [show, setShow] = useState(false);

  return (
    <header id='header' className='d-flex align-items-center'>
      <div className='container d-flex align-items-center'>
        <a href='index.html' className='logo me-auto'>
          <img src='assets/img/logo.jpeg' alt='...' className='img-fluid' />
        </a>

        <nav id='navbar' className='navbar'>
          <ul>
            <li>
              <a className='nav-link scrollto' href='#hero'>
                Home
              </a>
            </li>
            <li>
              <a className='nav-link scrollto' href='#about'>
                About
              </a>
            </li>
            <li>
              <a className='nav-link scrollto' href='#PopularDesign'>
                Popular Products
              </a>
            </li>

            <li className='dropdown'>
              <a href='#topbar'>
                <span>Our Products </span>{' '}
                <i className='bi bi-chevron-down'></i>
              </a>
              <ul>
                <li>
                  <a href='#OurProduct'>All</a>
                </li>
                <li>
                  <a href='#OurProduct'>WOODMIX DOOR FRAME</a>
                </li>
                <li>
                  <a href='#OurProduct'>WOODMIX MEDIA WALLS</a>
                </li>
                <li>
                  <a href='#OurProduct'>WOODMIX STORAGE UNIT</a>
                </li>
                <li>
                  <a href='#OurProduct'>WOODMIX KITCHEN CABINET</a>
                </li>
              
              </ul>
            </li>
           
            <li>
              <a className='nav-link scrollto' href='#contact'>
                Contact
              </a>
            </li>
            <li>
              <a className='getstarted scrollto' href='#about'>
                Get Started
              </a>
            </li>
          </ul>
          <i
            className='bi bi-list mobile-nav-toggle'
            onClick={() => {
              setShow(!show);
            }}
          ></i>
          {show && (
            <nav id='navbar' className='navbar navbar-mobile'>
              <ul>
                <li>
                  <a className='nav-link scrollto active' href='#hero'>
                    Home
                  </a>
                </li>
                <li>
                  <a className='nav-link scrollto' href='#about'>
                    About
                  </a>
                </li>
                <li>
                  <a className='nav-link scrollto' href='#services'>
                    Best selling products
                  </a>
                </li>

                <li className='dropdown'>
                  <a href='#topbar'>
                    <span>Our Products </span>{' '}
                    <i className='bi bi-chevron-down'></i>
                  </a>
                  <ul>
                    <li>
                      <a href='#topbar'>WPC Doors</a>
                    </li>
                    <li>
                      <a href='#topbar'>WPC Cupboards</a>
                    </li>
                    <li>
                      <a href='#topbar'>WPC Racks</a>
                    </li>
                    <li>
                      <a href='#topbar'>WPC kitchen Cabinets</a>
                    </li>
                    <li>
                      <a href='#topbar'>WPC Wardrobes</a>
                    </li>
                    <li>
                      <a href='#topbar'>WPC Commerial Furniture</a>
                    </li>
                  </ul>
                </li>

                <li>
                  <a className='nav-link scrollto ' href='#portfolio'>
                    Portfolio
                  </a>
                </li>
                <li>
                  <a className='nav-link scrollto' href='#team'>
                    Team
                  </a>
                </li>
                <li>
                  <a className='nav-link scrollto' href='#contact'>
                    Contact
                  </a>
                </li>
                <li>
                  <a className='getstarted scrollto' href='#about'>
                    Get Started
                  </a>
                </li>
              </ul>
              <i
                className='bi mobile-nav-toggle bi-x'
                onClick={() => setShow(!show)}
              ></i>
            </nav>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
