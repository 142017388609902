import React, { useState, useEffect, useContext } from 'react';
import { WoodmixContext } from '../context';

const Review = () => {
  const { data } = useContext(WoodmixContext);
  const { hero } = data;
  const [index, setIndex] = useState(0);
  useEffect(() => {
    let slider = setInterval(() => {
      setIndex((oldIndex) => {
        let index = oldIndex + 1;
        if (index > hero.length - 1) {
          index = 0;
        }
        return index;
      });
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);
  const nextPerson = () => {
    setIndex((oldIndex) => {
      let index = oldIndex + 1;
      if (index > hero.length - 1) {
        index = 0;
      }
      return index;
    });
  };
  const prevPerson = () => {
    setIndex((oldIndex) => {
      let index = oldIndex - 1;
      if (index < 0) {
        index = hero.length - 1;
      }
      return index;
    });
  };
  return (
    <section id='hero'>
      <div
        key={hero[index].hero_id}
        id='heroCarousel'
        data-bs-interval='5000'
        className='carousel slide carousel-fade'
        data-bs-ride='carousel'
      >
        <ol className='carousel-indicators' id='hero-carousel-indicators'></ol>
        <div className='carousel-inner' role='listbox'>
          <div className='carousel-item active'>
            <img
             src={`https://woodmix.pk/woodmix/image/${hero[index].hero_img}`}
              
              alt=''
              className='w-100 h-100'
            />
            <div className='carousel-container'>
              <div className='container'>
                <h2 className='animate__animated animate__fadeInDown'>
                  {hero[index].hero_heading}
                </h2>
                <p className='animate__animated animate__fadeInUp'>
                  {hero[index].hero_disc}
                </p>
                <a
                  href='#about'
                  className='btn-get-started animate__animated animate__fadeInUp scrollto'
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>

        <a
          className='carousel-control-prev'
          href='#heroCarousel'
          role='button'
          data-bs-slide='prev'
        >
          <span
            className='carousel-control-prev-icon bi bi-chevron-left'
            aria-hidden='true'
            onClick={prevPerson}
          ></span>
        </a>

        <a
          className='carousel-control-next'
          href='#heroCarousel'
          role='button'
          data-bs-slide='next'
        >
          <span
            className='carousel-control-next-icon bi bi-chevron-right'
            aria-hidden='true'
            onClick={nextPerson}
          ></span>
        </a>
      </div>
    </section>
  );
};

export default Review;
