import React from 'react';
import { WoodmixContext } from '../context';
const About = () => {
  const { data } = React.useContext(WoodmixContext);
  const { about } = data;
  return (
    <section id='about' className='about'>
      <div className='container'>
        <div className='section-title'>
          <h2>About Us</h2>
          <p>{about.about_p}</p>
        </div>

        <div className='row'>
          <div className='col-lg-6 order-1 order-lg-2'>
            <img
              src={`https://woodmix.pk/woodmix/image/${about.about_img}`}
              className='img-fluid'
              alt=''
            />
          </div>
          <div className='col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content'>
            <h3>{about.about_heading}</h3>
            <p className='lh-lg'>{about.about_disc}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About;
