import React, { useState } from 'react';
import Menu from './Menu';
import Categories from './Categories';
import { WoodmixContext } from '../../context';
function App() {
  const { data } = React.useContext(WoodmixContext);
  const { portfolio_product } = data;
  const allCategories = [
    'all',
    ...new Set(portfolio_product.map((item) => item.portfolio_category)),
  ];
  const [menuItems, setMenuItems] = useState(portfolio_product);
  const [categories  , setCategories] = useState(allCategories);
  
  

  const filterItems = (category) => {
    if (category === 'all') {
      setMenuItems(portfolio_product);
      return;
    }
    const newItems = portfolio_product.filter(
      (item) => item.portfolio_category === category
    );
    setMenuItems(newItems);
  };
  return (
    <section id='OurProduct' className='portfolio'>
      <div className='container'>
        <div className='section-title'>
          <h2>OUR PRODUCTS</h2>
          <p>
          "Experience the epitome of style and durability with Woodmix PVC furniture. Crafted to perfection, our furniture combines timeless elegance with the resilience of PVC. Transform your living space and indulge in the luxury of long-lasting beauty. Discover Woodmix PVC furniture today."
          </p>
        </div>
        <div className='row'>
          <div className='col-lg-12 d-flex justify-content-center'>
            <ul id='portfolio-flters'>
              <Categories categories={categories} filterItems={filterItems} />
              <Menu items={menuItems} />
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default App;
