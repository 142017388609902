import React from 'react';
import { WoodmixContext } from '../context';
const Contact = () => {
  const { data } = React.useContext(WoodmixContext);
  const { contact } = data;
  return (
    <section id='contact' className='contact'>
      <div className='container'>
        <div className='section-title'>
          <h2>Contact</h2>
          <p>
          Dear Website User,

         Thank you for visiting our website and your interest in getting in touch with us. We are currently working on developing our "Contact Us" functionality, and we apologize for any inconvenience this may cause.
          </p>
        </div>

        <div className='row'>
          <div className='col-lg-5 d-flex align-items-stretch'>
            <div className='info'>
              <a href='tel:03340300077' className='phone'>
                <i className='bi bi-phone'></i>
                <h4>Call:</h4>
                <p>{contact.contact_number}</p>
              </a>
              <a href='mailto:tajorakzai@gmail.com' className='email'>
                <i className='bi bi-envelope'></i>
                <h4>Email:</h4>
                <p>{contact.contact_email}</p>
              </a>
              <a
                href='https://goo.gl/maps/yh3zzFxXoV2PYEx36'
                target='_blank'
                rel='noreferrer'
                className='address'
              >
                <i className='bi bi-geo-alt'></i>
                <h4>Location:</h4>
                <p>{contact.contact_location}</p>
              </a>
              <iframe
                title='map'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.8543109337033!2d72.88905337467371!3d33.635019339647506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df976b7defbea1%3A0x2b3ada56cd5088c2!2sWoodmix%20Doors%20WPC%20Doors%20and%20HDPE%20Chaukat%20Tarnol%20Branch!5e0!3m2!1sen!2s!4v1687009078743!5m2!1sen!2s" 
                width='100%'
                height='290'
                style={{ border: '0' }}
                allowFullScreen=''
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
              ></iframe>
            </div>
          </div>

          <div className='col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch'>
            <form
              action='https://woodmix.pk/woodmix/contact.php'
              method='post'
              //role='form'
              className='php-email-form'
            >
              <div className='row'>
                <div className='form-group col-md-6'>
                  <label htmlFor='name'>Your Name</label>
                  <input
                    type='text'
                    name='name'
                    className='form-control'
                    id='name'
                    disabled={true}
                    required
                  />
                </div>
                <div className='form-group col-md-6 mt-3 mt-md-0'>
                  <label htmlFor='name'>Your Email</label>
                  <input
                    type='email'
                    className='form-control'
                    name='email'
                    id='email'
                    disabled={true}
                    required
                  />
                </div>
              </div>
              <div className='form-group mt-3'>
                <label htmlFor='name'>Subject</label>
                <input
                  type='text'
                  className='form-control'
                  name='subject'
                  id='subject'
                  disabled={true}
                  required
                />
              </div>
              <div className='form-group mt-3'>
                <label htmlFor='name'>Message</label>
                <textarea
                  className='form-control'
                  name='message'
                  rows='10'
                  disabled={true}
                  required
                ></textarea>
              </div>
              <div className='my-3'>
                <div className='loading'>Loading</div>
                <div className='error-message'></div>
                <div className='sent-message'>
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className='text-center'>
                <button type='submit' disabled={true} >Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Contact;
