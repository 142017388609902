import React, { useState } from 'react';

import Form from './forms/adminform';

function Admin() {
    const [userValid, setUserValid] = useState({});

 
  const handleSubmit = (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      
      const requestData = Object.fromEntries(formData.entries());
     
      fetch('https://woodmix.pk/woodmix/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      })
      .then(response => response.json())
      .then(data => {
         
         setUserValid(data);
       
       })
      .catch(error => console.error(error))
    
};

  return (
    <>
      {userValid.UserValid === "true"? (
        <Form/>
      ) : (
        <div className='container' style={{ height: '100vh' }}>
          <div className='d-flex justify-content-center item-center top-50 start-50 translate-middle'>
            <div className='user_card '>
              {userValid.UserValid === "false" ? (
                <h4 className='fw-bold text-danger mx-3'>
                  invalid username/password
                </h4>
              ) : (
                ''
              )}
              <div className='d-flex justify-content-center form_container'>
                <form onSubmit={handleSubmit} method='POST'>
                  <div className='input-group mb-3'>
                    <div className='input-group-append'>
                      <span className='input-group-text'>
                        <i className='fas fa-user'></i>
                      </span>
                    </div>
                    <input
                      required
                      id='user'
                      name='user'
                      type='text'
                      className='form-control input_user'
                      placeholder='username'
                    />
                  </div>
                  <div className='input-group mb-2'>
                    <div className='input-group-append'>
                      <span className='input-group-text'>
                        <i className='fas fa-key'></i>
                      </span>
                    </div>
                    <input
                      required
                      id='pass'
                      name='pass'
                      type='password'
                      className='form-control input_pass'
                      placeholder='password'
                    />
                  </div>

                  <div className='d-flex justify-content-center mt-3 login_container'>
                    <input
                      type='submit'
                      name='button'
                      className='btn login_btn'
                      value='Login'
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Admin;

































