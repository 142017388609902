import React from 'react';
const Footer = () => {
  return (
    <>
      <footer id='footer'>
        <div className='container'>
          <h3>WOODMIX</h3>
          <p>SAVE NATURE LIVE BETTER</p>
          <div className='social-links'>
            <a href='#about' className='twitter'>
              <i className='bx bxl-twitter'></i>
            </a>
            <a href='#about' className='facebook'>
              <i className='bx bxl-facebook'></i>
            </a>
            <a href='#about' className='instagram'>
              <i className='bx bxl-instagram'></i>
            </a>
            <a href='#about' className='google-plus'>
              <i className='bx bxl-skype'></i>
            </a>
            <a href='#about' className='linkedin'>
              <i className='bx bxl-linkedin'></i>
            </a>
          </div>
          <div className='copyright'>
            &copy; Copyright{' '}
            <strong>
              <span>WOODMIX</span>
            </strong>
            . All Rights Reserved
          </div>
      {/*     <div className='credits'>
            Designed by <a href='#about'>MONTYSOL</a>
          </div> */}
        </div>
      </footer>
      <a
        href='#topbar'
        className='back-to-top d-flex align-items-center justify-content-center'
      >
        <i className='bi bi-arrow-up-short'></i>
      </a>
    </>
  );
};
export default Footer;
